import React from 'react';
import { motion } from 'framer-motion';
import './heroSectionV2.css';
import logohero from '../../assets/img/logohero.png';

const heroSectionV2 = () => {
  const scrollToSection = () => {
    const section = document.getElementById('separadorFormulario');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="hero-section" id="home">
      <motion.div
        className="background-blob"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, x: [0, 50, 0], y: [0, 50, 0] }}
        transition={{ duration: 5, repeat: Infinity, repeatType: 'mirror' }}
      />
      <div className="content">
        <div className="text-column">
          <h1><strong>LÍDERES CONSCIENTES DE SU IMPACTO</strong></h1>
          <p>- Capaces de escuchar.</p>
          <p>- Capaces de generar estrategias con su equipo y la organización.</p>
          <p>- Capaces de crear contextos que les permitan alcanzar los resultados deseados.</p>
          <button className="cta-button" onClick={scrollToSection}>
            Quiero una entrevista
          </button>
        </div>
        <div className="image-column">
          <img src={logohero} alt="Logo plg de hero section" />
        </div>
      </div>
    </div>
  );
};

export default heroSectionV2;