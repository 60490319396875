import './sepForm.css';

const sepForm = () => {
    return (
        <div id="separadorFormulario" className="separadorFormulario-container"> 
  
        </div>
    );
};

export default sepForm;