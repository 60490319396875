import React, { useState } from 'react';
import './headerPLG.css';
import logo from '../../assets/img/logoheader.png';

const HeaderPLG = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const closeNav = () => {
        setIsNavOpen(false); // Cierra el menú al hacer clic en un enlace
    };

    return (
        <header className="App-header navbar navbar-expand-lg navbar-light">
            <button className="navbar-brand btn" onClick={() => window.location.href = '/'}>
                <img src={logo} alt="Logo" className="logo" />
            </button>
            <button className="navbar-toggler" type="button" onClick={toggleNav}>
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`}>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="#home" onClick={closeNav}>Inicio</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#about" onClick={closeNav}>¿Cómo lo hacemos?</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#dates" onClick={closeNav}>Fechas</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#separadorFormulario" onClick={closeNav}>Contacto</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/brochure.pdf" download="brochure.pdf" onClick={closeNav}>Brochure</a>
                    </li>
                    <li className="nav-item">
                        <button className="language-button btn btn-primary" onClick={closeNav}>Es</button>
                    </li>
                </ul>
            </div>
        </header>
    );
};

export default HeaderPLG;