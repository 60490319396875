import React from 'react';
import './sepFocuses.css'; // Archivo CSS para estilos
import plgIconBlue from '../../assets/img/plg-icon-blue.png';


const SepFocuses = () => {
  return (
    <div className="SeparadorFocosV2-container">
      <div className="ColumnaASF2">
        <h2 style={{ textAlign: 'left' }}><b>FOCOS DEL PROGRAMA</b></h2>
      </div>
      <div className="ColumnaBSF2">
        <img src={plgIconBlue} alt="FOTO" className="img-fluid focos-img" style={{ textAlign: 'right' }}/>
      </div>
    </div>
  );
}

export default SepFocuses;