import React from 'react';
import './focuses.css';

const Focuses = () => {
    return (
        <div className="focos-container">
            <table className="focos-table">
                <tbody>
                    <tr>
                        <td className="narrow">1</td>
                        <td className="wide"><p>Aprenderás a <b>gestionar promesas y hacer pedidos</b>, que incluye desarrollar tu capacidad de negociar, declinar o contra ofertar. </p></td>
                        <td className="narrow">4</td>
                        <td className="wide"><p>Aprenderás a ampliar tu posibildad de gestionar desafíos, obstaculos <b>-gestión de quiebres.</b> </p></td>
                    </tr>
                    <tr>
                        <td className="narrow">2</td>
                        <td className="wide"><p>Aprenderás a sostener conversaciones para la acción <b>CPA</b>, siendo un <b>cliente y ejecutor efectivo.</b></p></td>
                        <td className="narrow">5</td>
                        <td className="wide"><p>Aprenderás a sostener <b>conversaciones efectivas</b> (13 conversaciones de los equipos efectivos) y a escuchar cuales son las <b>conversaciones faltantes.</b> </p></td>
                    </tr>
                    <tr>
                        <td className="narrow">3</td>
                        <td className="wide"><p>Reconocerás el nivel de <b>compromiso</b> de tu equipo y como gestionarlos.</p></td>
                        <td className="narrow">6</td>
                        <td className="wide"><p>Aprenderás a gestionar tu <b>capadidad</b> y la de tu equipo.</p></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Focuses;