import React from 'react';
import './impact.css';
import logoImpacto from '../../assets/img/logoImpacto.png';


const Impact = () => {
    return (
        <div className="impacto-container">
            <img src={logoImpacto} alt="Logo Impacto" className="impacto-logo" />
            <div className="impacto-text-container">
                <h1 className="impacto-title"><strong>LÍDERES</strong> CON CAPACIDAD DE:</h1>
            </div>
        </div>
    );
};

export default Impact;