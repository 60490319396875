import React, { useState } from 'react';
import './FAQ.css';


const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const questions = [
        {
            question: "¿Si no tengo gente a cargo puedo hacer el programa?",
            answer: " SI, somo lideres no solo por tener gente a cargo, somo lideres porque cuidamos de una promesa con otros, tenemos una visión compartida y necesitamos hacer que pasen cosas con otros, coordinando, avisando posibles desafíos rediseñando etc.   Vamos a tener un proyecto y ese proyecto se puede realizar con personas con las que trabajas en el día a día."
        },
        {
            question: "¿Si en este momento estoy mirando que elección tomar en mi camino profesional, y  este proceso me llama la atención, es para mí?",
            answer: "SI, mas allá del lugar de trabajo somos lideres donde estamos con quien compartimos promesas, además desde la estructura del programa, y espacios donde escuchas a otros lideres es un espacio que te va a permitir también ver miradas diferentes frente a tu momento, tendrás un mentor durante los 7 meses con el que podrás además de estar aprendiendo de liderazgo ir poniendo tus inquietudes frente a tus momentos de vida."
        },
        {
            question: "Estoy recientemente con un equipo, ¿este programa me genera valor?",
            answer: "SI, más allá del tiempo, ya lo estás haciendo, venimos de una cultura que hasta hace muy pocos años es un tema de conversación, más o menos unos 20 años, esto hace que tengamos que aprender a ser lideres, y en el espacio vas a poder compartir con personas con diferentes conocimientos, miradas, experiencia, y eso seguro va a enriquecer tu proceso de aprendizaje."
        },
        {
            question: "¿Por qué debo hacer una entrevista?",
            answer: "Porque si bien es un programa para varios perfiles, estilos de personas, nos encanta conocer de cerca a las personas que estarán en el programa, es un programa en el que queremos cuidar la cercanía con el alumno, y esto empieza desde el minuto 0. "
        },
        {
            question:"¿Qué medio de pago puedo utilizar?",
            answer:"Se hace a través de tarjeta de crédito, o transferencia bancaria, dentro de la entrevista también conversamos de la forma de pago. "
        }
    ];

    const toggleAnswer = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="faq-container">
            <h2 className="faq-title"><strong>¿En qué podemos ayudarte?</strong></h2>
            {questions.map((item, index) => (
                <div key={index} className="faq-item">
                    <div className="faq-question" onClick={() => toggleAnswer(index)}>
                        {item.question}
                    </div>
                    {activeIndex === index && <div className="faq-answer">{item.answer}</div>}
                </div>
            ))}
        </div>
    );
};

export default FAQ;
