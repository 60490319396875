import React from 'react';
import './sepHowDo.css';
import Imagen1 from '../../assets/img/icon-bluebar-1.png'; 
import Imagen2 from '../../assets/img/icon-bluebar-2.png';

const sepHowDo = () => {
    return (
        <div className="separador" id="about">
            <h2 className="separador-titulo"><strong>NUESTRO CAMINO DE APRENDIZAJE</strong></h2>
            <img src={Imagen1} alt="Imagen 1" className="separador-imagen" />
            <img src={Imagen2} alt="Imagen 2" className="separador-imagen-final" />
        </div>
    );
}

export default sepHowDo;