import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './02_impact.css';

const impactP2 = () => {
    return (
        <div className="impactoParte2-container">
            <div className="row impacto-columnas text-white">
                <div className="col-12 col-md-4 impacto-column">
                    <p><b>Diseñar</b> conversaciones que producen resultados.</p>
                </div>
                <div className="col-12 col-md-4 impacto-column">
                    <p><b>Reconocer</b> las prácticas (acciones) que cambian la historia en tu equipo.</p>
                </div>
                <div className="col-12 col-md-4 impacto-column">
                    <p><b>Escuchar</b> y co-construir con tu equipo.</p>
                </div>
            </div>
        </div>
    );
};

export default impactP2;