import React from 'react';
import './sepDates.css';
import plgIconBlue from '../../assets/img/plg-icon-blue.png'; 

const sepDates = () => {
    return (
        <div className="containerSFechas" id="dates">
            <div className="columnSFechas left">
                <p className="title"><strong>FECHAS</strong></p>
            </div>
            <div className="columnSFechas right">
                <img src={plgIconBlue} alt="FOTO" className="image" />
            </div>
        </div>
    );
};

export default sepDates;