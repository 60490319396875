import React from 'react';
import './who.css';
import recursoAzulV2 from '../../assets/img/recurso-azul-v2.png'; // Asegúrate de que la ruta de la imagen sea correcta

const who = () => {
  return (
    <section className="dirigido-v2-section-DV2">
      <div className="left-column-DV2">
        <div className="top-row-DV2">
          <h2 className="title-DV2"><strong>¿A QUIEN VA DIRIGIDO ESTE PROGRAMA?</strong></h2>
        </div>
        <div className="bottom-row-DV2">
          <img src={recursoAzulV2} alt="Recurso Azul" className="image-DV2" />
        </div>
      </div>
      <div className="right-column-DV2">
        <div className="text-column-DV2">
        <ul className="bullet-list-DV2">
            <li>Líderes que quieran elevar su nivel de conciencia para impactar en sus equipos, con sus pares, la organización y la cultura organizacional.  </li>
            <li>Líderes que están en el proceso de conectar con sus equipos para llegar a los resultados o ya lo hacen y quieren seguir aprendiendo.</li>
            <li>Líderes que están viviendo momentos de cambio de su organización y requieren replantear el juego en su liderazgo.</li>
            <li>Líderes empezando a liderar equipos.</li>
            <li>Líderes que se están haciendo preguntas de comos y no saben como.</li>
            <li>¡Este programa es para ti!</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default who;