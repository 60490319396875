import React, { useState } from 'react';
import './KnowTeam.css';
import Bob from '../../assets/img/Bob.png';
import Lina from '../../assets/img/Lina.png';
import ModalComponent from './ModalComponent';

const KnowTeam = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleOpenModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const linaContent = (
        <div className="textContainerModal">
            <h2>Lina Correa</h2>
            <p><strong>Directora del Programa</strong></p>
            <p>20 años de experiencia en grandes organizaciones con alcance nacional y regional.</p>
            <p>
                Socia fundadora de <strong>INNER Transformación Cultural</strong> desde Junio de 2012 dirigiendo y ejecutando proyectos de: 
                Alineación de equipos, cultura organizacional, y desarrollo de líderes.
            </p>
            <p>
                Trabajó en <strong>KIMBERLY CLARK COLOMBIA</strong>, donde se conectó con la realidad de los equipos, sus interacciones, a nivel Colombia y región Andina.
            </p>
            <p>
                Formadora de Coaches en <strong>NewField Network</strong> del año 2013 al 2019. En la actualidad forma parte del Instituto de Liderazgo Generativo donde acompaña como coach mentora.
            </p>
        </div>
    );

    const bobContent = (
        <div className="textContainerModal">
            <h2>Bob Dunham</h2>
            <p><strong>Fundador de IGL Global (Institute for Generative Leadership)</strong></p>
            <p><strong>Creador del Liderazgo Generativo.</strong></p>
            <p>Es ingeniero de Sistemas de la Universidad de Stanford. Trabajó en el Silicon Valley como VP de operaciones en empresas multinacionales. Desarrolló la disciplina del <strong>Liderazgo Generativo</strong> como una combinación de lo aprendido como Ingeniero, VP y aprendiz de Fernando Flores y Richard Strozzi (entre otros) en las dimensiones ontológica, somática y humana.</p>
            <p>Su disciplina del <strong>Liderazgo Generativo</strong> ofrece a líderes y coaches la posibilidad de incorporar habilidades, comportamientos y conversaciones que se pueden <strong>VER, ESCUCHAR, PRACTICAR</strong> y <strong>APRENDER</strong> haciendo del liderazgo una profesión posible para todos.</p>
        </div>
    );

    return (
        <section className="conoce-el-equipo-section">
            <div className="column">
                <h1 className="team-title"><strong>CONOCE EL EQUIPO</strong></h1>
            </div>
            <div className="column">
                <h3 className="subtitle" onClick={() => handleOpenModal(linaContent)}><b>Lina Correa</b></h3>
                <h4 className="leader-title">Directora de programa</h4>
                <img src={Lina} alt="Lina Correa" className="team-image" onClick={() => handleOpenModal(linaContent)} />
            </div>
            <div className="column">
                <h3 className="subtitle" onClick={() => handleOpenModal(bobContent)}><b>Bob Dunham</b></h3>
                <h4 className="leader-title">Fundador de IGL Global</h4>
                <img src={Bob} alt="Bob Dunham" className="team-image" onClick={() => handleOpenModal(bobContent)} />
            </div>
            {isModalOpen && (
                <ModalComponent isOpen={isModalOpen} onClose={handleCloseModal}>
                    {modalContent}
                </ModalComponent>
            )}
        </section>
    );
};

export default KnowTeam;