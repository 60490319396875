import React from 'react';
import './dates.css';
import fechaImgMini from '../../assets/img/fecha-img-mini.png'; // Importa la imagen
import fechaImg from '../../assets/img/fecha-img.png'; // Importa la imagen para C1 Fila 1-3
import plg25Img from '../../assets/img/plg-25.png'; // Importa la imagen para C1 Fila 4-6  <img src={fechaImg} alt="Fecha Icon" className="fecha-img" />   <img src={plg25Img} alt="PLG Icon" className="plg-img" />

const datesv2 = () => {
    return (
        <div className="fechas-container">
            <table className="fechas-table">
                <tbody>
                    <tr>
                        <td className="c1" rowSpan="3"><img src={plg25Img} alt="PLG Icon" className="plg-img" /></td>
                        <td className="c2"><b>Primera Conferencia</b></td>
                        <td className="c3"><img src={fechaImgMini} alt="Fecha Icon" className="fecha-img" /></td>
                        <td className="c4"><strong>FEBRERO 14 y 15</strong></td>
                        <td className="c5">Todo el día.</td>
                    </tr>
                    <tr>
                        <td className="c2"><b>Conferencia Somática</b></td>
                        <td className="c3"><img src={fechaImgMini} alt="Fecha Icon" className="fecha-img" /></td>
                        <td className="c4"><strong>MARZO 15</strong></td>
                        <td className="c5">3 horas.</td>
                    </tr>
                    <tr>
                        <td className="c2"><b>Segunda Conferencia</b></td>
                        <td className="c3"><img src={fechaImgMini} alt="Fecha Icon" className="fecha-img" /></td>
                        <td className="c4"><strong>ABRIL 19</strong></td>
                        <td className="c5">Todo el día.</td>
                    </tr>
                    <tr>
                        <td className="c1" rowSpan="3"><img src={fechaImg} alt="Fecha Icon" className="fecha-img" /></td>
                        <td className="c2"><b>Conferencia Somática</b></td>
                        <td className="c3"><img src={fechaImgMini} alt="Fecha Icon" className="fecha-img" /></td>
                        <td className="c4"><strong>MAYO 17</strong></td>
                        <td className="c5">3 horas.</td>
                    </tr>
                    <tr>
                        <td className="c2"><b>Conferencia DISC</b></td>
                        <td className="c3"><img src={fechaImgMini} alt="Fecha Icon" className="fecha-img" /></td>
                        <td className="c4"><strong>JULIO 19</strong></td>
                        <td className="c5">3 horas.</td>
                    </tr>
                    <tr>
                        <td className="c2"><b>Tercera Conferencia</b></td>
                        <td className="c3"><img src={fechaImgMini} alt="Fecha Icon" className="fecha-img" /></td>
                        <td className="c4"><strong>OCTUBRE 24 y 25</strong></td>
                        <td className="c5">Todo el día</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default datesv2;
