import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; 

import WhatsAppButton from './components/13_whatsapp/WhatsAppButton';

import HeaderPLG from './components/01_header/headerPLG';
import HeroSectionV2 from './components/02_heroSectionV2/heroSectionV2';
import GenerativeLeader from './components/03_generativeleader/generativeLeader';
import Impact from './components/04_impact/impact';
import ImpactP2 from './components/04_impact/02_impact';
import SepFocuses from './components/05_programFocuses/sepFocuses';
import Focuses from './components/05_programFocuses/focuses';
import SepHowDo from './components/06_howDo/sepHowDo';
import HowDo from './components/06_howDo/howDo';
import SepDates from './components/07_dates/sepDates';
import DatesV2 from './components/07_dates/datesV2';
import Who from './components/08_who/who';
import KnowTeam from './components/09_knowTeam/knowTeam';
import SepForm from './components/10_form/sepForm';
import Form from './components/10_form/form';
import FAQ from './components/11_faq/FAQ';

import FooterG from './components/12_footer/FooterG';

function App() { 
  return (
    <div className="App">
      <div className="contentx">
        <HeaderPLG/>
        <WhatsAppButton />
        <HeroSectionV2/>
        <GenerativeLeader/>
        <Impact/>
        <ImpactP2/>
        <SepFocuses/>
        <Focuses/>
        <SepHowDo/>
        <HowDo/>
        <SepDates/>
        <DatesV2/>
        <Who/>
        <KnowTeam/>
        <SepForm/>
        <div className="section_register_1">
            <Form/>
        </div>
        <FAQ /> 
      </div>
      <FooterG/>
    </div>
  );
}

export default App;