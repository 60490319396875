import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube, FaGlobe } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.css';
import Logof from '../../assets/img/logo-footer.png';

const Footer = () => {
    return (
        <footer className="App-footer container-fluid" >
            <div className="row">
                <div className="column-1 col-md-4 col-sm-12">
                    <div className="social-icons">
                        <a href="https://www.facebook.com/igllatam" aria-label="Facebook">
                            <FaFacebook size={20} className="social-icon" />
                        </a>                    
                        <a href="https://www.instagram.com/igllatam?igsh=dngxa2dmcHFkbXc3" aria-label="Instagram">
                            <FaInstagram size={20} className="social-icon" />
                        </a>
                        <a href="https://www.linkedin.com/company/instituto-de-liderazgo-generativo-latam" aria-label="LinkedIn">
                            <FaLinkedin size={20} className="social-icon" />
                        </a>
                        <a href="https://www.youtube.com/@IGLLatam/videos" aria-label="Youtube">
                            <FaYoutube size={20} className="social-icon" />
                        </a>
                        <a href="https://liderazgogenerativola.com/ccg1/" aria-label="Globe">
                            <FaGlobe size={20} className="social-icon" />
                        </a>
                    </div>
                </div>
                <div className="column-2 col-md-4 col-sm-12">
                    <img src={Logof} alt="Sample" className="footer-image" />
                </div>
                <div className="column-3 col-md-4 col-sm-12">
                    <p>FAQ</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;