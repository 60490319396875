import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './WhatsAppButton.css';

const WhatsAppButton = () => {
    const handleWhatsAppClick = () => {
        window.open('https://wa.me/573103900613', '_blank');
    };

    return (
        <div className="whatsapp-button" onClick={handleWhatsAppClick}>
            <FaWhatsapp size={50} color="#fff" />
        </div>
    );
};

export default WhatsAppButton;