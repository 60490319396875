import React from 'react';
import { Modal } from 'react-bootstrap';
import { motion } from 'framer-motion';
import './ModalComponent.css';

const ModalComponent = ({ isOpen, onClose, children }) => {
    return (
        <Modal
            show={isOpen}
            onHide={onClose}
            centered
            dialogClassName="custom-modal desktop-modal" // clase para hacer un media query  especifico 
        >
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
            </motion.div>
        </Modal>
    );
};

export default ModalComponent;