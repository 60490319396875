import React from 'react';
import './howDo.css';
import Icon1 from '../../assets/img/icon-1.png';
import Icon2 from '../../assets/img/icon-2.png';
import Icon3 from '../../assets/img/icon-3.png';
import Icon4 from '../../assets/img/icon-21.png';
import Icon5 from '../../assets/img/icon-22.png';
import Icon6 from '../../assets/img/icon-23.png';
import Icon7 from '../../assets/img/icon-31.png';
import Icon8 from '../../assets/img/icon-32.png';
import Icon9 from '../../assets/img/icon-33.png';

const howDo = () => {
    return (
        <div className="como-lo-hacemos">
            <table className="table">
                <tbody>
                    <tr>
                        <td><img src={Icon1} alt="Imagen 1" className="imagen-cuadrada" /></td>
                        <td><strong>7.5</strong> meses.</td>
                        <td><img src={Icon4} alt="Imagen 1" className="imagen-cuadrada" /></td>
                        <td><b>3</b> conferencias, 2 de ellas de la mano con Bob Dunham.</td>
                        <td><img src={Icon7} alt="Imagen 1" className="imagen-cuadrada" /></td>
                        <td>Desarrollo de un <b>proyecto</b> con tu <b>propio equipo,</b> basado en tu plan de desarrollo. </td>
                    </tr>
                    <tr>
                        <td><img src={Icon2} alt="Imagen 1" className="imagen-cuadrada" /></td>
                        <td><b>7</b> guías por tema.</td>
                        <td><img src={Icon5} alt="Imagen 1" className="imagen-cuadrada" /></td>
                        <td><b>8</b> video conferencias.</td>
                        <td><img src={Icon8} alt="Imagen 1" className="imagen-cuadrada" /></td>
                        <td><b>Equipo</b> de aprendizaje.</td>
                    </tr>
                    <tr>
                        <td><img src={Icon3} alt="Imagen 1" className="imagen-cuadrada" /></td>
                        <td><b>8</b> sesiones de <b>mentoring</b> uno a uno.</td>
                        <td><img src={Icon6} alt="Imagen 1" className="imagen-cuadrada" /></td>
                        <td>Conferencia de <b>Mapas Relacionales.</b></td>
                        <td><img src={Icon9} alt="Imagen 1" className="imagen-cuadrada" /></td>
                        <td><b>2</b> Conferencias de profundización <b>somática</b>.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default howDo;