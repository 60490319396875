import React from 'react';
import './generativeLeader.css';
import sectionEndImage from '../../assets/img/section-end.png';// Importa la imagen section-end


const generativeLeader = () => {
    return (
        <section className="generative-leadership-section">
            <div className="generative-leadership-container">
                <div className="text-content">   {/* Este contenedor columna der.*/}
                    <h3><b>¿QUÉ ES SER UN LIDER GENERATIVO?</b></h3>
                </div>
                <div className="paragraph-container"> {/* Este contenedor columna izq.*/}
                    <p>Un Líder Generativo es capaz de crear espacios seguros e inspiradores, que permita al equipo generar resultados desde lo que les importa cuidar.</p>
                    <h4 className="subtitulo"><strong>Un Lider Generativo declara el futuro con el que su equipo se compromete.</strong> </h4>
                    <p>Un Líder Generativo crea productividad y buena vida en las organizaciones.</p>
                </div>
            </div>
            <div className="section-end-image-container">
                <img src={sectionEndImage} alt="End of section" className="section-end-image" />
            </div>
        </section>
    );
};

export default generativeLeader;