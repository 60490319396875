import React, { useEffect } from 'react';

const Form = () => {
  useEffect(() => {

    const scriptId = 'active-campaign-form-script';
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://librelula.activehosted.com/f/embed.php?id=60';
      script.charset = 'utf-8';
      script.async = true;
      document.body.appendChild(script);
    }


  }, []);

  return <div className="_form_60"></div>;
};

export default Form;